import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getMachineImage } from '../../../../../../../../functions/getMachineImage';
import { Stack, Typography } from '@mui/material';

const MachinePhoto = () => {
	const [image, setImage] = useState('');
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getMachineImage(organization.org);
				setImage(response.imageUrl);
				console.log(response.imageUrl);
			} catch (error) {
				console.error('Error fetching machine image:', error);
			}
		}
		fetchData();
	}, [organization]);

	return image.length ? (
		<img src={image} alt='' style={{ width: '100%', height: '100%', borderRadius: '1rem', objectFit: 'cover', }} />
	) : (
		<Stack
			direction='column'
			sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
			<Typography
				variant='h5'
				textAlign={'center'}
				sx={{
					padding: '1rem',
					margin: '1rem',
				}}>
				NO MACHINE IMAGE FOUND
			</Typography>
		</Stack>
	);
};

export default MachinePhoto;
