import { Chip, Paper, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { useContext, useEffect, useState } from 'react';
import { getNumFeedsDailyAvg } from '../../../../../../functions/getNumFeedsDailyAvg';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';

const NumFeedsDailyAvg = ({ selectedWeekly }) => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { organization } = useContext(OrganizationContext);
	const [numFeedsDailyAvg, setNumFeedsDailyAvg] = useState(null);
	const [percentChange, setPercentChange] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const response = await getNumFeedsDailyAvg(
				selectedWeekly.year,
				selectedWeekly.yearWeek,
				organization._id
			);
			setNumFeedsDailyAvg(response.numFeedsDailyAvg);
			setPercentChange(response.percentChange);
		}
		if (organization && selectedWeekly) {
			fetchData();
		}
	}, [organization, selectedWeekly]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Avg. Feeds Per Day' value={numFeedsDailyAvg || 'N/A'}></KPI>
				{percentChange && (
					<Chip
						sx={{ width: '100%', backgroundColor: percentChange > 0 ? '#d2f1e1' : '#f6685b' }}
						label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last week`}
					/>
				)}
			</div>
		</Paper>
	);
};

export default NumFeedsDailyAvg;
