import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getOutlets } from '../../../../functions/getOutlets';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, Typography, Box } from '@mui/material';
import { addNewOutlet } from '../../../../functions/addNewOutlet';

const Outlets = () => {
	const [outlets, setOutlets] = useState([]);
	const [newOutletName, setNewOutletName] = useState('');
	const [newOutletNotes, setNewOutletNotes] = useState('');
	const { organization } = useContext(OrganizationContext);

	// Get the organization's existing outlets
	const fetchOutlets = async () => {
		const response = await getOutlets(organization._id);
		setOutlets(response);
	};

	// Get the organization's existing outlets on first load
	useEffect(() => {
		fetchOutlets();
	}, [organization]);

	const handleAddOutlet = async () => {
		await addNewOutlet(newOutletName, newOutletNotes, organization._id);
		setNewOutletName('');
		setNewOutletNotes('');
		await fetchOutlets();
	};

	return (
		<div style={{ minHeight: 600, width: '100%', flex: 1 }}>
			{/* Explanation Section */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '1rem', // Padding on all sides
					textAlign: 'center',
				}}
			>
				<Typography variant="body1" gutterBottom>
					Outlets are specific locations where food comes from in your location or organization. 
					Machines often come pre-loaded with commonly used outlets like 'banquets', 
					'restaurant', or 'cafeteria' for example, but you can customize your outlets to suit your needs. This will allow users to see how much food is being recycled from these individual outlets.
				</Typography>
			</Box>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Outlet Name</TableCell>
						<TableCell colSpan={2}>Notes</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{outlets.map((outlet) => (
						<TableRow key={outlet._id}>
							<TableCell>{outlet.outletName}</TableCell>
							<TableCell colSpan={2}>{outlet.notes}</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell>
							<TextField
								value={newOutletName}
								onChange={(e) => setNewOutletName(e.target.value)}
								placeholder='Outlet Name (ie. Banquet, Restaurant, In-Room Dining, etc.) '
								fullWidth
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={newOutletNotes}
								onChange={(e) => setNewOutletNotes(e.target.value)}
								placeholder='Notes'
								fullWidth
							/>
						</TableCell>
						<TableCell colSpan={2}>
							<Button variant='contained' onClick={handleAddOutlet} sx={{ width: '100%' }}>
								Add Outlet
							</Button>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</div>
	);
};

export default Outlets;
