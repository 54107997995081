import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import styles from './styles';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getTopUsersLast7Days } from '../../functions/getTopUsersLast7Days';
import { useTheme } from '@mui/material';
import leaderboardPodium from '../../../../assets/leaderboardPodium.png';
import { useTranslation } from 'react-i18next';

const Leaderboard = () => {
	const theme = useTheme();
	const classes = styles(theme);

	const [topUsers, setTopUsers] = useState([]);
	const { organization } = useContext(OrganizationContext);
	const { t } = useTranslation();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getTopUsersLast7Days(organization.org);
				console.log("Fetched top users:", response); // Log to verify data structure
				setTopUsers(response || []); // Fallback to empty array if response is undefined
			} catch (error) {
				console.error('Error fetching top users:', error);
				setTopUsers([]); // Set to empty array if there's an error
			}
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, [organization]);

	const renderUserInfo = (user, index) => (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			flex={1}
			key={index}>
			<Typography variant='h6' fontWeight='bold' style={{ textAlign: 'center' }}>
				{user?.FIRST} {user?.LAST}
			</Typography>
			<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
				{user?.weight ? `${user.weight.toFixed(0)} lbs` : 'N/A'}
			</Typography>
		</Box>
	);

	const rearrangedUsers = () => {
		if (!Array.isArray(topUsers) || topUsers.length < 3) {
			console.log("Top users data (insufficient or undefined):", topUsers); // Log if data is missing
			return topUsers || []; // Return topUsers if defined, else an empty array
		}
		return [topUsers[1], topUsers[0], topUsers[2]]; // Rearrange if we have 3 or more users
	};

	return (
		<div style={classes.leaderboardCard}>
			<div style={classes.cardTitle}>
				<Typography variant='h5' fontWeight={'bold'}>
					{t('Leaderboard')}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					height: '100%',
				}}>
				<Typography variant='h6' style={{ width: '100%', textAlign: 'center' }}>
					{t('Top Users - Last 7 Days')}
				</Typography>
				<Box style={classes.userInfoContainer}>{rearrangedUsers().map(renderUserInfo)}</Box>
				<Box style={classes.podiumImageContainer}>
					<img src={leaderboardPodium} alt='Podium' style={classes.podiumImage} />
				</Box>
			</div>
		</div>
	);
};

export default Leaderboard;
