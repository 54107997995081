import { isValidDate } from './isValidDate';

export const showPreLaunch = (organization) => {
	// Check if goLive date exists
	if (!organization.goLive) {
		return true; // Show pre-launch content if there's no goLive date
	}

	// If goLive date exists, proceed with the current logic
	if (isValidDate(organization.goLive)) {
		const goLiveTimestamp = new Date(organization.goLive).getTime();
		const currentTimestamp = Date.now();
		return goLiveTimestamp > currentTimestamp;
	} else {
		return false;
	}
};
