import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { updateActualCovers } from '../../../../functions/updateActualCovers';
import ActualTableMessaging from './ActualTableMessaging';

function createData(id, outletID, outletName, projected, actual) {
    return { id, outletID, outletName, projected, actual };
}

export const ActualTable = ({ organization, outlets, year, month, week }) => {
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        if (outlets && outlets.length > 0 && week) {
            const newRows = outlets.map((outlet, index) =>
                createData(
                    index,
                    outlet.outletID,
                    outlet.outletName,
                    outlet[`${week}proj`],
                    outlet[`${week}act`]
                )
            );
            setRows(newRows);
        } else {
            setRows(null);
        }
    }, [outlets, week]);

    const handleActualChange = (id, value) => {
        const parsedValue = value === '' ? null : parseInt(value);
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === id ? { ...row, actual: parsedValue } : row))
        );
    };

    const handleSubmit = (id) => {
        const row = rows.find((row) => row.id === id);
        updateActualCovers(organization, year, month, week, row.outletID, row.actual);
    };

    return year && month && week && rows ? (
        <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Outlet Name</TableCell>
                        <TableCell align='right'>Projected</TableCell>
                        <TableCell align='right'>Actual</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component='th' scope='row' align='left'>
                                {row.outletName}
                            </TableCell>
                            <TableCell align='right'>
                                {row.projected !== null ? row.projected : ''}
                            </TableCell>
                            <TableCell align='right'>
                                <input
                                    type='text'
                                    value={row.actual !== null ? row.actual : ''}
                                    onChange={(e) => handleActualChange(row.id, e.target.value)}
                                    style={{ marginRight: '10px' }} // Spacing between input and button
                                />
                                <button
                                    onClick={() => handleSubmit(row.id)}
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        border: 'none',
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold', // Make text bold
                                    }}
                                >
                                    SUBMIT
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <ActualTableMessaging wk={week} mo={month} yr={year} rws={rows}></ActualTableMessaging>
    );
};
