import { Chip, Paper, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { useContext, useEffect, useState } from 'react';
import { getDailyWeight } from '../../../../../../functions/getDailyWeight';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';

const DailyWeight = ({ selectedWeekly }) => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { organization } = useContext(OrganizationContext);
	const [dailyWeight, setDailyWeight] = useState(null);
	const [percentChange, setPercentChange] = useState(null);

	useEffect(() => {
		const fetch = async () => {
			const response = await getDailyWeight(
				selectedWeekly.year,
				selectedWeekly.yearWeek,
				organization._id
			);
			setDailyWeight(response.dailyWeight);
			setPercentChange(response.percentChange);
		};
		if (organization && selectedWeekly) {
			fetch();
		}
	}, [organization, selectedWeekly]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Daily Weight' value={dailyWeight || 'N/A'} unit={'lbs'}></KPI>
				{percentChange && (
					<Chip
						sx={{ width: '100%', backgroundColor: percentChange > 0 ? '#d2f1e1' : '#f6685b' }}
						label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last week`}
					/>
				)}
			</div>
		</Paper>
	);
};

export default DailyWeight;
