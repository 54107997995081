import axios from 'axios';

export const deactivateUser = async (userId) => {
	const url = `/.netlify/functions/deactivateUser`;
	console.log(userId);
	try {
		const res = await axios.post(url, { userId });
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};