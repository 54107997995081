/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { BrandLogo } from '../CustomerHUD/components/BrandLogo/BrandLogo';
import DigestionTankStatus from '../CustomerHUD/components/DigestionTankStatus/DigestionTankStatus';
import CallToActionStatus from '../CustomerHUD/components/CallToAction/CallToActionStatus';
import EventStatus from '../CustomerHUD/components/EventSection/EventSection';
import Leaderboard from '../CustomerHUD/components/Leaderboard/Leaderboard';
import Celebrations from '../CustomerHUD/components/Celebrations/Celebrations';
import Reminders from '../CustomerHUD/components/Reminders/Reminders';
import RecentFeedStatus from './components/RecentFeeds/RecentFeeds';

import { getActiveFaults } from './functions/getActiveFaults';
// import { recordHUDConnectionStart } from './functions/recordHUDConnectionStart';
import { getOrgById } from '../CustomerDashboard/functions/getOrgById';

import { OrganizationContext } from '../../context/OrganizationContext';

import contaminantIcon from '../../assets/icons/contaminantIcon.png';
import containerChangeIcon from '../../assets/icons/containerChangeIcon.png';
import serviceIcon from '../../assets/icons/serviceIcon.png';
import contaminantQR from '../../assets/qr/contaminantQR.png';
import thumbsUpIcon from '../../assets/icons/thumbsUpIcon.png';
import serviceQR from '../../assets/qr/bestPracticesQR.png';
import demoQR from '../../assets/qr/demoQR.png';
import BGIcon from '../../assets/G_logo.png';
import closeDoorIcon from '../../assets/icons/closeDoorIcon.png';
import estopIcon from '../../assets/icons/estopIcon.png';
import HUDHelpQR from '../../assets/qr/hud-help.png';
import bridgingIcon from '../../assets/icons/bridging-icon.png';
import HUD_example from '../../assets/examples/HUD_example.png';
import { useTranslation } from 'react-i18next';

import {
	bottomSection,
	mainSection,
	logoSection,
	midSectionBottom,
	midSectionTop,
	actionMessage,
	eventSection,
	callToActionSection,
	statusSection,
	topSection,
	callToActionBottomBar,
	sectionLCR,
	sectionQR,
	feedsSection,
	noActionMessage,
} from '../CustomerHUD/styles';
import { UserLocationContext } from '../../context/UserLocationContext';
import { getLocationByOrgName } from './functions/getLocationByOrgName';
import i18next from 'i18next';
import { showPreLaunch } from '../../utils/showPreLaunch';

const icons = {
	contaminantIcon,
	serviceIcon,
	bridgingIcon,
	containerChangeIcon,
	estopIcon,
	thumbsUpIcon,
	closeDoorIcon,
};

const CustomerHUD2 = () => {
	const [icon, setIcon] = useState();
	const [QR, setQR] = useState();
	const [action, setAction] = useState();
	const [description, setDescription] = useState();
	const [showCallToAction, setShowCallToAction] = useState(false);
	const [sessionID] = useState(uuidv4());
	const [isLoading, setIsLoading] = useState(true);
	const { t, i18n } = useTranslation();
	const [reload, setReload] = useState(false); // State to trigger re-render

	const location = useLocation();
	const { setOrganization, organization } = useContext(OrganizationContext);
	const { setUserLocation } = useContext(UserLocationContext);

	// Use a ref to ensure we only call recordHUDConnectionStart once
	const connectionStarted = useRef(false);

	// Function to parse query parameters
	const getQueryParams = (query) => {
		return new URLSearchParams(query);
	};

	// toggle language selection

	useEffect(() => {
		const languages = ['en', 'es'];
		let index = 0;

		const intervalId = setInterval(() => {
			// Toggle language
			index = (index + 1) % languages.length; // Alternate between 0 and 1
			// i18n.changeLanguage('fr');

			i18n.changeLanguage(languages[index]);
		}, 5000);

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId);
	}, [i18n]);

	// Refresh when new translation is added
	// useEffect(() => {
	// 	const handleTranslationAdded = () => {
	// 		window.location.reload();
	// 	};
	// 	i18next.on('translationAdded', handleTranslationAdded);
	// 	return () => {
	// 		i18next.off('translationAdded', handleTranslationAdded);
	// 	};
	// }, []);

	useEffect(() => {
		const handleTranslationAdded = () => {
			console.log('New translation added. No reload triggered.');
		};
		i18next.on('translationAdded', handleTranslationAdded);
		return () => {
			i18next.off('translationAdded', handleTranslationAdded);
		};
	}, []);

	// Fetch and set organization on component mount or when the URL changes
	useEffect(() => {
		const fetchOrgData = async () => {
			const queryParams = getQueryParams(location.search);
			const orgId = queryParams.get('query');

			if (orgId) {
				setIsLoading(true); // Set loading to true when starting to fetch data
				try {
					const data = await getOrgData(orgId);
					setOrganization(data); // Update the OrganizationContextc with the fetched data

					// if (!connectionStarted.current) {
					// 	recordHUDConnectionStart(orgId, sessionID);
					// 	connectionStarted.current = true;
					// }
					if (data.org) {
						const locationName = await fetchLocationByOrgName(data.org);
						setUserLocation(locationName.locationName);
					}
				} catch (error) {
					console.error('Failed to fetch organization data:', error);
				} finally {
					setIsLoading(false); // Set loading to false once the data is fetched
				}
			} else {
				setIsLoading(false); // Set loading to false if orgId is not in the query params
			}
		};

		fetchOrgData();

		// const handleBeforeUnload = (event) => {
		// 	const payload = JSON.stringify({ orgID: organization._id, sessionID: sessionID });
		// 	navigator.sendBeacon('/.netlify/functions/recordHUDConnectionEnd', payload);
		// };

		// window.addEventListener('beforeunload', handleBeforeUnload);

		// return () => {
		// 	window.removeEventListener('beforeunload', handleBeforeUnload);
		// 	const payload = JSON.stringify({ orgID: organization._id, sessionID: sessionID });
		// 	navigator.sendBeacon('/.netlify/functions/recordHUDConnectionEnd', payload);
		// };
	}, [location.search, organization._id, setOrganization, setUserLocation]);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getActiveFaults(organization._id);
				if (response !== 'none' && response) {
					// Ensures response is valid and not an error placeholder
					setAction(response);
					setIcon(icons[response.iconHUD]);
					setQR(contaminantQR);
					setDescription('Fault Description Here'); // Use actual description if available
					setShowCallToAction(true);
				} else {
					setShowCallToAction(false);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
				setShowCallToAction(false); // Explicitly handle error by disabling the call to action
			}
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, [organization]);

	if (isLoading) {
		return null; // or return <LoadingSpinner />; if you have a loading spinner component
	}

	if (showPreLaunch(organization)) {
		return <img src={HUD_example}></img>;
	} else {
		return (
			<div className='body'>
				<div style={mainSection}>
					<div style={topSection}>
						<div style={logoSection}>
							<BrandLogo />
						</div>
						<div style={eventSection}>
							<EventStatus />
						</div>
					</div>

					<div style={midSectionTop}>
						<div style={statusSection}>
							<DigestionTankStatus />
						</div>
						{showCallToAction ? (
							<div style={actionMessage}>
								<img
									src={icon}
									alt='Action Icon'
									style={{ width: 'auto', height: '75%', marginLeft: '5%' }}
								/>
								<div
									style={{
										flexGrow: 1,
										textAlign: 'center',
										color: 'black',
										fontSize: '3rem',
										fontWeight: 'bold',
									}}>
									{action.actionHUD}
									{/* {description} */}
								</div>
							</div>
						) : (
							<div style={noActionMessage}>
								<img
									src={thumbsUpIcon}
									alt='Action Icon'
									style={{ width: 'auto', height: '60%', marginLeft: '5%' }}
								/>
								<div
									style={{
										flexGrow: 0.75,
										textAlign: 'center',
										color: 'black',
										fontSize: '2rem',
										fontWeight: 'bold',
									}}>
									{t('Keep up the Good Work!')}
									{/* {description} */}
								</div>
							</div>
						)}
					</div>
					{showCallToAction ? (
						<div
							style={{
								...callToActionBottomBar,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-evenly',
							}}>
							{/* Icon and Action Message inside the Bottom Bar */}
							<img
								src={icon}
								alt='Action Icon'
								style={{ width: '200px', height: '200px' }} // Adjust size as needed
							/>
							<div style={{ padding: '0 20px' }}>
								<iframe
									width='533'
									height='300'
									src={action.videoHUD + '?rel=0'}
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture '></iframe>
							</div>
							<img
								src={QR}
								alt='Action QR'
								style={{ width: '200px', height: '200px' }} // Adjust size as needed
							/>
						</div>
					) : (
						<div style={midSectionBottom}>
							<div style={sectionLCR}>
								<Leaderboard />
							</div>
							<div style={sectionLCR}>
								<Celebrations />
							</div>
							<div style={sectionLCR}>
								<Reminders />
							</div>
						</div>
					)}

					<div style={bottomSection}>
						<div style={sectionQR}>
							<img src={BGIcon} alt='BG Icon' style={{ width: '50px', height: '50px' }} />
							<img
								src={HUDHelpQR}
								alt='Help QR'
								style={{ width: '60px', height: '60px' }} // Adjust size as needed
							/>
						</div>
						<div style={feedsSection}>
							<RecentFeedStatus />
						</div>
					</div>
				</div>
			</div>
		);
	}
};

const getOrgData = async (orgId) => {
	if (orgId) {
		return await getOrgById(orgId);
	}
	return null;
};

const fetchLocationByOrgName = async (orgName) => {
	if (orgName) {
		return await getLocationByOrgName(orgName);
	}
	return null;
};

export default CustomerHUD2;
