import axios from 'axios';

// works
export const getDigWeight = async (orgID) => {
	// const url = `http://localhost:5000/getDigWeight/${orgID}`;
	const url = `/.netlify/functions/getDigWeight?orgID=${orgID}`;

	var digWeight = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				digWeight = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return digWeight;
};

// export const getDigWeight = async (organization) => {
// 	// const url = `http://localhost:5000/getDigWeight/${devicelabel}`;
// 	const url = `/.netlify/functions/getDigWeight?devicelabel=${devicelabel}`;

// 	var digWeight = 0;

// 	await axios
// 		.get(url)
// 		.then((res) => {
// 			if (res.data != null && res.data !== false) {
// 				digWeight = res.data;
// 			} else {
// 				console.log('failed');
// 			}
// 		})
// 		.catch((err) => {
// 			console.log(err);
// 		});

// 	return digWeight;
// };
