import { useContext, useEffect, useState } from 'react';
import { Chip, Paper, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getDailyWeightByDateRange } from '../../../../../../functions/getDailyWeightByDateRange';

const DailyWeight = ({ selectedMonthYear }) => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const [dailyWeight, setDailyWeight] = useState(null);
	const [percentChange, setPercentChange] = useState(null);

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getDailyWeightByDateRange(
				organization._id,
				selectedMonthYear.year,
				selectedMonthYear.month
			);
			setDailyWeight(response.dailyWeight);
			setPercentChange(response.percentChange);
		}
		if (selectedMonthYear?.year && selectedMonthYear?.month && organization?._id) {
			fetchData();
		}
	}, [selectedMonthYear, organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Daily Weight' value={dailyWeight || 'N/A'} unit={'lbs'}></KPI>
				{percentChange && (
					<Chip
						sx={{ width: '100%', backgroundColor: percentChange > 0 ? '#d2f1e1' : '#f6685b' }}
						label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last month`}
					/>
				)}
			</div>
		</Paper>
	);
};

export default DailyWeight;
