import { Chip, Paper, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getActualCoversByDateRange } from '../../../../../../functions/getActualCoversByDateRange';

const ActualCovers = ({ selectedMonthYear }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [actualCovers, setActualCovers] = useState();
	const [percentChange, setPercentChange] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getActualCoversByDateRange(
				organization._id,
				selectedMonthYear.year,
				selectedMonthYear.month
			);
			setActualCovers(response.totalActualCovers);
			setPercentChange(response.percentChange);
		}
		if (selectedMonthYear && organization) {
			fetchData();
		}
	}, [selectedMonthYear, organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Actual Covers' value={actualCovers >= 0 ? actualCovers : 'N/A'}></KPI>
				{percentChange && (
					<Chip
						sx={{ width: '100%', backgroundColor: percentChange > 0 ? '#d2f1e1' : '#f6685b' }}
						label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last month`}
					/>
				)}
			</div>
		</Paper>
	);
};

export default ActualCovers;
