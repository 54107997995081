import { useContext, useState } from 'react';
import { monthlyReportsContainer } from './styles';
import { Button, Paper, useTheme } from '@mui/material';

import WeeklyReports from './components/WeeklyReports/WeeklyReports';
import MonthlyReports from './components/MonthlyReports/MonthlyReports';
import AllTimeTrends from './components/AllTimeTrends/AllTimeTrends';

import reports1 from '../../../../assets/examples/aggScreenshots/reports1.png';
import reports2 from '../../../../assets/examples/aggScreenshots/reports2.png';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import ExamplePageWarning from '../../../CustomerDashboard/components/ExamplePageWarning/ExamplePageWarning';

const Reports = () => {
	const { organization } = useContext(OrganizationContext);

	const [currentTab, setCurrentTab] = useState('monthlyReports');
	const theme = useTheme();

	if (!showPreLaunch(organization)) {
		return (
			<Paper
				style={monthlyReportsContainer}
				sx={{ border: `1px solid ${theme.palette.border.main}` }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						color: theme.palette.primary.main,
						gap: '1rem',
						margin: '1rem 1rem 0 1rem',
						border: `1px solid ${theme.palette.border.main}`,
						padding: '0.5rem',
						borderRadius: '0.5rem 0.5rem 0 0',
					}}>
					<Button
						sx={{
							color:
								currentTab === 'monthlyReports' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: currentTab === 'monthlyReports' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor:
									currentTab === 'monthlyReports' ? '#d5f0e1' : 'transparent',
							},
						}}
						onClick={() => {
							setCurrentTab('monthlyReports');
						}}>
						Monthly Reports
					</Button>
					{/* <Button
						sx={{
							color: currentTab === 'weeklyReports' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: currentTab === 'weeklyReports' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: currentTab === 'weeklyReports' ? '#d5f0e1' : 'transparent',
							},
						}}
						onClick={() => {
							setCurrentTab('weeklyReports');
						}}>
						Weekly Reports
					</Button> */}
					<Button
						sx={{
							color: currentTab === 'allTimeTrends' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: currentTab === 'allTimeTrends' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: currentTab === 'allTimeTrends' ? '#d5f0e1' : 'transparent',
							},
						}}
						onClick={() => {
							setCurrentTab('allTimeTrends');
						}}>
						All-Time Trends
					</Button>
				</div>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						color: '#000',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '1rem',
						margin: '0 1rem 1rem 1rem',
						border: `1px solid ${theme.palette.border.main}`,
						padding: '0.25rem',
						borderRadius: '0 0 0.5rem 0.5rem',
					}}>
					{currentTab === 'weeklyReports' && <WeeklyReports />}
					{currentTab === 'monthlyReports' && <MonthlyReports />}
					{currentTab === 'allTimeTrends' && <AllTimeTrends />}
				</div>
			</Paper>
		);
	} else {
		return (
			<>
				<ExamplePageWarning />
				<img src={reports1} alt='reportt1' />
				<img src={reports2} alt='reports2' />
			</>
		);
	}
};

export default Reports;
