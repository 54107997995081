import { Chip, Paper, Tooltip, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getFoodRecycledPerCoverMonthlyAvgByDateRange } from '../../../../../../functions/getFoodRecycledPerCoverMonthlyAvgByDateRange';

const FoodRecycledPerCoverMonthlyAvg = ({ selectedMonthYear }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [foodRecycledPerCover, setFoodRecycledPerCover] = useState();
	const [percentChange, setPercentChange] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getFoodRecycledPerCoverMonthlyAvgByDateRange(
				organization._id,
				selectedMonthYear.year,
				selectedMonthYear.month
			);
			setFoodRecycledPerCover(response.avgFWPerCover);
			setPercentChange(response.percentChange);
		}
		if (organization && selectedMonthYear) fetchData();
	}, [selectedMonthYear, organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI
					label='Food Recycled Per Cover Monthly Avg'
					value={
						foodRecycledPerCover && foodRecycledPerCover !== 'NaN'
							? foodRecycledPerCover
							: 'N/A'
					}
					unit={'oz'}></KPI>
				{percentChange && (
					<Chip
						sx={{ width: '100%', backgroundColor: percentChange < 0 ? '#d2f1e1' : '#f6685b' }}
						label={`${percentChange > 0 ? '+' : ''}${
							percentChange ? percentChange : 0
						}% since this month last year`}
					/>
				)}
			</div>
		</Paper>
	);
};

export default FoodRecycledPerCoverMonthlyAvg;
